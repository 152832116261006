<template>
  <v-chip
    class="mb-1 pb-1 pt-1 v-chip__custom"
    close
    color="grey"
    close-icon="mdi-close"
    @click:close="$emit('remove', true)"
    @click.stop>
    <div
      class="d-flex flex-wrap"
      style="height: 100%;">
      <div class="pr-2 align-self-center">
        {{ name }}
      </div>
      <div>
        <v-combobox
          :ref="createRef"
          v-model="searchVal"
          :items="items"
          dense
          solo
          :search-input.sync="searchValSync"
          style="width: 105px;"
          class="pt-0 mt-0 search-filter__dropdown"
          @change="onUpdate" />
      </div>
    </div>
  </v-chip>
</template>
<script>
export default {
  name: 'SearchFilterInputBool',
  props: {
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    itemValue: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    alias: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchVal: 'true',
    searchValSync: 'true',
  }),
  computed: {
    createRef() {
      return this.name.concat('-', this.index);
    },
  },
  watch: {
    itemValue: {
      async handler() {
        if (this.autofocus) {
          await this.$nextTick();
          this.searchValSync = this.itemValue ? 'true' : 'false';
          await this.$nextTick();
          this.$refs[this.createRef].focus();
          await this.$nextTick();
          this.$refs[this.createRef].activateMenu();
          return;
        }
        this.searchVal = this.itemValue ? 'true' : 'false';
      },
      immediate: true,
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update', {
        value: this.searchVal, final: true,
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .search-filter {
  &__dropdown {
    height: 28px !important;
    div[role="combobox"] {
      padding-left: 6px;
    }
    .v-input__control {
      min-height: 28px !important;
      max-height: 28px !important;
      margin-bottom: 0 !important;
    }
    .v-input__slot {
      margin-bottom: 0 !important;
      padding-right: 0 !important;
      padding-left: 6px !important;
    }
    .v-select__slot {
      padding-top: 0 !important;
    }
    input {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-left: 0!important;
      min-width: 40px !important;
      font-size: 14px !important;
      text-overflow: ellipsis;
      opacity: initial !important;
    }
    .v-input__append-inner:nth-child(3) {
      display: none;
    }
    .v-icon {
      font-size: 14px;
    }
  }
}
</style>